<template>
  <div class="data-source-list">
    <div class="header">
      <div class="left" />
      <div class="right">
        <el-button @click="$router.back()">返回</el-button>
      </div>
    </div>
    <search-wrap class="search-wrap">
      <el-form inline label-suffix="：">
        <el-form-item label="列名">
          <el-input v-model="search.colName" clearable placeholder="请输入列名" />
        </el-form-item>
        <el-button @click="onSearch">搜索</el-button>
      </el-form>
      <div class="handle-btn">
        <el-button
          type="primary"
          icon="el-icon-plus"
          plain
          @click="dialogVisible = true"
        >
          新建数据列
        </el-button>
      </div>
    </search-wrap>
    <!-- 表格 -->
    <el-table
      ref="table"
      v-loading="loading"
      class="list-table"
      :data="tableData"
      border
      stripe
      highlight-current-row
      @sort-change="onSortChange"
    >
      <el-table-column prop="colName" min-width="200" label="列名" />
      <el-table-column prop="colDesc" label="列描述" />
      <el-table-column prop="colValues" align="center" label="值域" />
      <el-table-column
        prop="dataType"
        width="100"
        align="center"
        label="数据类型"
      >
        <template slot-scope="scope">
          <span>{{ dataTypeFilter(scope.row.dataType) }}</span>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        prop="isPublic"
        width="120"
        label="是否发布"
      >
        <template slot-scope="{row: {isPublic}}">
          {{ isPublic === 0 ? '否' : isPublic === 1 ? '是' : '否' }}
        </template>
      </el-table-column>

      <el-table-column label="操作" width="250">
        <template slot-scope="scoped">
          <el-button type="primary" size="mini" @click="() => {dialogVisible = true; editForm = scoped.row}">编辑</el-button>
          <el-button type="danger" size="mini" @click="onDel(scoped.row)">删除</el-button>
          <el-button v-if="scoped.row.isPublic === 0" size="mini" @click="changeStatus(scoped.row)">发布</el-button>
          <el-button v-else size="mini" @click="changeStatus(scoped.row)">取消发布</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination-wrap">
      <el-pagination
        center
        :current-page="pagination.pageNo"
        :total="pagination.total"
        :page-size="pagination.pageSize"
        :page-sizes="pagination.sizes"
        :layout="pagination.layout"
        :hide-on-single-page="false"
        background
        @size-change="onSizeChange"
        @current-change="onCurrentChange"
      />
    </div>
    <!-- 弹窗区 -->
    <el-dialog
      title="新建数据报告"
      :visible.sync="dialogVisible"
      width="40%"
      @close="editForm = {}"
    >
      <el-form
        :rules="editFormRules"
        :model="editForm"
        label-suffix="："
        label-width="100px"
        label-position="right"
      >
        <el-form-item label="列名" prop="colName">
          <el-input v-model.trim="editForm.colName" />
        </el-form-item>
        <el-form-item label="列描述" prop="colDesc">
          <el-input v-model.trim="editForm.colDesc" />
        </el-form-item>
        <el-form-item label="值域" prop="colValues">
          <el-input v-model.trim="editForm.colValues" />
        </el-form-item>
        <el-form-item label="数据类型" prop="dataType">
          <el-select v-model="editForm.dataType">
            <el-option
              v-for="item in dataTypeList"
              :key="item.label"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="是否发布" prop="isPublic">
          <el-switch v-model="editForm.isPublic" :active-value="1" :inactive-value="0" />
        </el-form-item>
      </el-form>

      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" :loading="editForm.loading" @click="editFormSubmit">保 存</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import paginationMixin from '@/mixins/pagination'
export default {
  name: 'DataSourceList',
  mixins: [paginationMixin],
  data() {
    const validateColValues = (rule, vlaue, callback) => {
      // 验证value 符合 value:key,value:key，符合这种格式，无需验证 ':' 两边值可以是任意值
      // name:空格有无都可以value
      const reg = /^(\s*([a-zA-Z0-9\u4e00-\u9fa5]+)\s*:\s*([a-zA-Z0-9\u4e00-\u9fa5]+)\s*,?)+$/
      if (!reg.test(vlaue)) {
        callback(new Error('请输入正确的值域，格式为：value: name,value1: name1'))
      } else {
        callback()
      }

    }
    return {
      search: {
        tableName: '',
        colName: ''
      },
      dialogVisible: false,
      editForm: {},
      loading: false,
      tableData: [],
      dataTypeList: [{
        label: '整数',
        value: 1
      }, {
        label: '字符串',
        value: 2
      }, {
        label: '日期',
        value: 3
      }],
      editFormRules: {
        colName: [
          { required: true, message: '请输入列名', trigger: 'blur' }
        ],
        colDesc: [
          { required: true, message: '请输入列描述', trigger: 'blur' }
        ],
        colValues: [
          { required: false, validator: validateColValues, trigger: 'blur' }
        ],
        dataType: [
          { required: true, message: '请选择数据类型', trigger: 'blur' }
        ]
      }
    }
  },
  mounted() {
    this.search.tableName = this.$route.query.tableName
    this.getDataList()
  },
  methods: {
    async getDataList() {
      this.loading = true
      const params = this.getParams(this.search)
      try {
        const { data = [], totalCount } = await this.$axios.get(this.$API.selectList, { params })
        this.loading = false
        this.tableData = data
        this.pagination.total = totalCount
      } finally {
        this.loading = false
      }
    },
    // 改变状态
    async changeStatus({ isPublic, tableColUnitId }) {
      const params = {
        id: tableColUnitId,
        isPublic: isPublic === 0 ? 1 : isPublic === 1 ? 0 : 1
      }
      try {
        const res = await this.$axios.get(this.$API.updateIsPublic, { params })
        if (res) {
          this.$message.success('操作成功')
          this.getDataList()
        }
      } catch (error) {
        console.log(error)
        this.$message.error(error.message)
      }
    },
    // 表单提交
    async editFormSubmit() {
      try {
        let URL = this.$API.saveTableColUnit
        if (this.editForm.tableColUnitId) {
          URL = this.$API.editTableColUnit
        }
        const params = {...this.editForm}
        params.tableName = this.$route.query.tableName
        const res = await this.$axios.post(URL, params)
        if (res) {
          this.$message.success('操作成功')
          this.dialogVisible = false
          this.getDataList()
        }
      } catch (error) {
        console.log('error: ', error)
        this.$message.error(error.message)
      }

    },
    // 删除
    async onDel(row) {
      this.$confirm(`此操作将永久删除${row.colName}, 是否继续?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async() => {
        try {
          const res = await this.$axios.get(this.$API.deleteTableColUnit, { params: {tableColUnitId: row.tableColUnitId} })
          if (res) {
            this.$message.success('删除成功')
            this.getDataList()
          }
        } catch (error) {
          console.log('error: ', error)
        }
      })
    },
    // 查看
    onCheck(row) {
      this.$router.push({
        name: '',
        query: {
          reportId: row.reportId
        }
      })
    },
    onSearch() {
      this.pagination.pageNo = 1
      this.getDataList()
    },
    dataTypeFilter(val) {
      const row = this.dataTypeList.find(item => item.value === val)
      return row.label
    }
  }
}
</script>

<style scoped lang="scss">
// scss
.data-source-list {
  .header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
  }
  .search-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
  }
  .handle-btn {
    margin-bottom: 10px;
  }
  .pagination-wrap {
    margin-top: 20px;
    display: flex;
    justify-content: center;
  }
}
</style>
