<template>
  <div class="data-source-list">
    <search-wrap class="search-wrap">
      <el-form inline label-suffix="：">
        <el-form-item label="描述">
          <el-input v-model="search.tableDesc" placeholder="请输入描述" />
        </el-form-item>
        <el-button @click="onSearch">搜索</el-button>
      </el-form>
    </search-wrap>
    <!-- 表格 -->
    <el-table
      ref="table"
      v-loading="loading"
      class="list-table"
      :data="tableData"
      border
      stripe
      highlight-current-row
      @sort-change="onSortChange"
    >
      <el-table-column prop="tableName" label="表名" />
      <el-table-column prop="tableDesc" label="表描述" />
      <el-table-column label="操作" width="150">
        <template slot-scope="scoped">
          <el-button type="primary" size="mini" @click="onCheck(scoped.row)">编辑</el-button>
          <el-button type="danger" size="mini" @click="onDel(scoped.row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination-wrap">
      <el-pagination
        center
        :current-page="pagination.pageNo"
        :total="pagination.total"
        :page-size="pagination.pageSize"
        :page-sizes="pagination.sizes"
        :layout="pagination.layout"
        :hide-on-single-page="false"
        background
        @size-change="onSizeChange"
        @current-change="onCurrentChange"
      />
    </div>
  </div>
</template>

<script>
import paginationMixin from '@/mixins/pagination'
export default {
  name: 'DataSourceList',
  mixins: [paginationMixin],
  data() {
    return {
      search: {
        tableDesc: ''
      },
      loading: false,
      tableData: []
    }
  },
  mounted() {
    this.getDataList()
  },
  methods: {
    async getDataList() {
      this.loading = true
      const params = this.getParams(this.search)
      try {
        const res = await this.$axios.get(this.$API.allTableName, {params})
        this.loading = false
        const { data = [], totalCount } = res
        this.tableData = data
        this.pagination.total = totalCount
      } finally {
        this.loading = false
      }
    },
    async onDel(row) {
      this.$confirm(`此操作将永久删除${row.tableName}, 是否继续?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async() => {
        try {
          const res = await this.$axios.delete(this.$API.deleteDataTable, { id: row.id })
          console.log('res: ', res)
          this.$message({
            type: 'success',
            message: '删除成功!'
          })
        } catch (error) {
          console.log('error: ', error)
        }
      })
    },
    // 查看
    onCheck(row) {
      console.log('row: ', row)
      this.$router.push({
        name: 'DataSourceColumn',
        query: {
          tableName: row.tableName
        }
      })
    },
    onSearch() {
      this.pagination.pageNo = 1
      this.getDataList()
    }
  }
}
</script>

<style scoped lang="scss">
// scss
.data-source-list {
  .search-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
  }
  .pagination-wrap {
    margin-top: 20px;
    display: flex;
    justify-content: center;
  }
}
</style>
